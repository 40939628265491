<template>
  <div id="stories" class="content-section">
    <img src="/flower-2.png" alt="flower" class="w-50 flower-top fade-in" />
    <h1 class="font-madina font-large-3 mt-2 text-warning">
      <feather-icon icon="HeartIcon" size="24"></feather-icon>
      Love Story
      <feather-icon icon="HeartIcon" size="24"></feather-icon>
    </h1>
    <div class="timeline pb-5">
      <div
        v-for="(story, idx) in stories"
        :key="idx"
        class="timeline-container warning scroll-fade-in"
      >
        <div class="timeline-icon">
          <feather-icon icon="HeartIcon" size="24" class="h-100"></feather-icon>
        </div>
        <div class="timeline-body">
          <img
            :src="story.attachment.link"
            alt="link"
            class="img-fluid rounded"
          />
          <h4 class="timeline-title">
            <span class="badge badge-secondary">{{ story.title }}</span>
          </h4>
          <p>{{ story.desc }}</p>
        </div>
      </div>
    </div>
    <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom fade-in" />
  </div>
</template>

<script>
export default {
  props: ["stories"],
};
</script>

<style>
#stories {
  background-image: url("/stories.jpg");
}
</style>