<template>
  <div id="lovers" class="content-section">
    <img src="/flower-2.png" alt="flower" class="w-50 flower-top" />
    <div class="groom-wrapper px-5 scroll-fade-in">
      <img :src="photos.groom" alt="groom" class="img-fluid" />
    </div>
    <div class="lovers-name px-2 scroll-fade-in">
      <a
        href="https://instagram.com/alfredo_sinaga"
        class="btn btn-link"
        target="_blank"
      >
        <feather-icon icon="InstagramIcon" size="24" class="mr-1" />
      </a>
      <h1 class="font-madina font-large-2">Alfredo Sinaga, S. Kom</h1>
      <p>
        The son of <strong class="text-shadow">Mr. Dohot Sinaga</strong> &
        <strong class="text-shadow">Mrs. Lussiana Simbolon</strong>
      </p>
      <h1 class="font-madina">To</h1>
      <p>
        The daughter of
        <strong class="text-shadow">(✝) Mr. St. Albert S Rumahombar</strong>
        & <strong class="text-shadow">Mrs. Rotain Lamriris Hutasoit</strong>
      </p>
      <h1 class="font-madina font-large-2">
        Apt. Maria Fransisca Rumahombar, S. Farm
      </h1>
      <a
        href="https://instagram.com/redrumahombar"
        class="btn btn-link"
        target="_blank"
      >
        <feather-icon icon="InstagramIcon" size="24" class="mr-1" />
      </a>
    </div>
    <div class="bride-wrapper px-5 scroll-fade-in">
      <img :src="photos.bride" alt="bride" class="img-fluid" />
    </div>
    <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom" />
  </div>
</template>

<script>
export default {
  props: ["photos"],
};
</script>

<style>
#lovers {
  background-image: url("/lovers.jpg");
}

#lovers * {
  color: #d67b27 !important;
}
</style>