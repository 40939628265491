<template>
  <div>
    <div :class="opening ? 'fade-out' : 'cover'" id="cover">
      <div id="cover-media-wrapper">
        <video autoplay muted loop id="cover-media">
          <!-- <source src="http://assets/bg.mp4" type="video/mp4" /> -->
          <source src="/video-cover.mp4" type="video/mp4" />
        </video>
        <div id="cover-media-overlay"></div>
      </div>
      <div id="content">
        <div
          class="text-center d-flex flex-column justify-content-center align-items-center"
          id="cover-text"
        >
          <h3 class="fade-in my-2">The Wedding Of</h3>
          <h1 class="fade-in font-madina mb-3 font-large-3">Alfredo & Maria</h1>
          <div v-if="guest">
            <h4 class="fade-in mb-2">You are invited!</h4>
            <b-button
              v-if="!opened"
              pill
              variant="outline-primary"
              class="px-5 fade-in"
              @click="openInvitation()"
            >
              <div
                class="d-flex align-items-center justify-content-center px-5"
              >
                <feather-icon icon="MailIcon" size="18" class="mr-1" />
                Open Invitation
              </div>
            </b-button>
            <b-button v-else pill variant="outline-primary">
              Opening...
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="opened" id="content">
      <audio autoplay loop style="display: none" id="content-music">
        <source src="/audio.mp3" type="audio/mpeg" />
      </audio>
      <intro />
      <lovers :photos="{ groom: items.groom, bride: items.bride }" />
      <main-info />
      <stories :stories="items.stories" />
      <galleries :galleries="items.galleries" />
      <wish-gift :guest="guest" />
      <div
        class="content-section bg-light"
        style="background-image: url('/intro-bg.jpg')"
      >
        <img src="/flower-2.png" alt="flower" class="w-50 flower-top" />
        <img src="/am.png" class="img-fluid my-2" />
        <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom" />
      </div>
      <button
        v-if="music"
        class="btn btn-sm btn-warning btn-music rounded-pill"
      >
        <feather-icon icon="PauseCircleIcon" @click="pauseMusic()" />
      </button>
      <button
        v-else
        @click="playMusic()"
        class="btn btn-sm btn-warning btn-music rounded-pill"
      >
        <feather-icon icon="PlayCircleIcon" />
      </button>
    </div>
    <div v-if="loaders.preLoad" class="fade" style="display: none">
      <audio muted>
        <source src="/audio.mp3" type="audio/mpeg" />
      </audio>
      <img src="/am.png" alt="" srcset="" />
      <img src="/flower-1.png" alt="" srcset="" />
      <img src="/flower-2.png" alt="" srcset="" />
      <img src="/flower-3.png" alt="" srcset="" />
      <img src="/flower-4.png" alt="" srcset="" />
      <img src="/intro-bg.jpg" alt="" srcset="" />
      <img src="/og-image.jpg" alt="" srcset="" />
      <img src="/wish-bg.jpg" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BButton } from "bootstrap-vue";
import Intro from "./components/intro";
import Lovers from "./components/lovers";
import MainInfo from "./components/main-info";
import Stories from "./components/stories";
import Galleries from "./components/galleries";
import WishGift from "./components/wish-gift";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    // components
    Intro,
    Lovers,
    MainInfo,
    Stories,
    Galleries,
    WishGift,
  },
  data() {
    return {
      items: null,
      opening: false,
      opened: false,
      stories: [],
      music: true,
      loaders: {
        items: true,
        preLoad: false,
        slug: true,
      },
      guest: null,
    };
  },
  beforeMount() {
    if (!!this.$route.query.s) {
      this.$http
        .post("https://api-peanut.bangnaga.net/api/guest", {
          slug: this.$route.query.s,
        })
        .then((res) => {
          this.guest = res.data;
        });
    }
  },
  mounted() {
    this.getData();
    setTimeout(() => {
      this.loaders.preLoad = true;
    }, 1000);
  },
  methods: {
    getData() {
      this.$http
        .get("https://api-peanut.bangnaga.net/api/attachments/peanut")
        .then((res) => {
          this.items = res.data;
        });
    },
    openInvitation() {
      // app.parentElement.requestFullscreen();
      this.opening = true;
      setTimeout(() => {
        this.opened = true;
      }, 800);
      this.playMusic();
    },
    playMusic() {
      let music = document.getElementById("content-music");
      if (music) {
        music.play();
        this.music = true;
      }
    },
    pauseMusic() {
      let music = document.getElementById("content-music");
      if (music) {
        music.pause();
        this.music = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
</style>

<style>
#cover {
  position: relative;
  z-index: 11;
}
#cover * {
  color: var(--primary);
}
#cover-media-wrapper {
  min-height: 100vh;
  width: 100%;
}
#cover-media-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  min-height: 100vh;
}
#cover-media {
  padding: 0 !important;
  margin: 0 !important;
  object-fit: cover;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0;
}
#cover-text {
  min-height: calc(100vh - 4rem);
}
#cover-text h1,
#cover-text h2,
#cover-text h3 {
  text-shadow: 0px 0px 15px white;
}

.content-section {
  min-height: 400px;
  padding: 2rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  /* overflow-x: hidden; */
}

.flower-top {
  margin-top: -2rem;
}

.flower-bottom {
  margin-bottom: -2rem;
}
</style>
