<template>
  <div id="wish-gift" class="content-section">
    <img src="/wish-bg.jpg" class="img-fluid" id="wish-bg" />
    <div class="position-relative">
      <img src="/flower-4.png" class="wish-flower-top-left" />
      <div class="card bg-transparent border-white rounded-lg">
        <div class="card-body p-1">
          <h1 class="font-madina font-large-2 text-light m-0">
            <feather-icon icon="MailIcon" size="26" class="mr-2" />
            Wedding Wish
            <feather-icon icon="MailIcon" size="26" class="ml-2" />
          </h1>
          <div v-if="guest" class="wish-form py-1">
            <div class="form-group">
              <div class="input-group" v-if="guest.is_group">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text border-warning bg-warning text-light font-weight-bolder"
                    id="basic-addon1"
                    >{{ guest.name }}</span
                  >
                </div>
                <input
                  type="text"
                  id="wish_sender"
                  ref="wishSender"
                  v-model="wish.sender"
                  class="form-control border-warning bg-light text-warning font-weight-bolder"
                  :placeholder="
                    guest ? `Name` : '🚫 Especially for invited guests'
                  "
                />
              </div>
              <div
                v-else
                class="form-control border-warning bg-light text-left text-warning font-weight-bolder"
              >
                <p
                  class="d-flex align-items-center m-0"
                  style="font-family: sans-serif"
                >
                  <feather-icon icon="UserIcon" class="mr-1" /> {{ guest.name }}
                </p>
              </div>
            </div>
            <div class="form-group">
              <textarea
                id="wish_message"
                ref="wishMessage"
                class="form-control border-warning bg-light"
                placeholder="wedding wish"
                v-model="wish.message"
                maxlength="250"
              ></textarea>
            </div>
            <div class="w-100 text-right">
              <button v-if="loaders.wish" class="btn btn-light">
                <span
                  class="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sending...
              </button>
              <button v-else class="btn btn-warning" @click="sendWishes()">
                <feather-icon icon="SendIcon" /> Send
              </button>
            </div>
          </div>
          <div v-else class="mb-5">
            <h1
              class="bg-white text-danger text-center font-weight-bolder rounded px-2 py-1"
            >
              <feather-icon
                icon="SlashIcon"
                size="32"
                style="margin-top: -5px"
              />
              Guest Only
              <feather-icon
                icon="SlashIcon"
                size="32"
                style="margin-top: -5px"
              />
            </h1>
          </div>
          <div class="wishes">
            <div
              class="wish-wrapper row m-0 fade-in"
              v-for="(wish, idx) in wishes"
              :key="idx"
            >
              <div class="wish-icon col-1 p-0">
                <div class="w-icon">
                  <feather-icon icon="UserIcon" size="18"></feather-icon>
                </div>
              </div>
              <div class="wish-content col-11">
                <div class="card bg-light">
                  <div class="card-body">
                    <p class="wish-sender text-truncate">
                      {{ wish.sender }}
                      <span v-if="wish.group" class="badge badge-warning"
                        >{{ wish.group }}
                      </span>
                    </p>
                    <p class="wish-message">
                      {{ wish.message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/flower-4.png" class="wish-flower-bottom-right" />
    </div>
    <div class="card bg-transparent shadow-none">
      <div class="card-body">
        <b-button
          class="btn btn-warning font-madina font-large-1"
          @click="showGift = true"
        >
          <div class="d-flex">
            <feather-icon icon="GiftIcon" size="26" class="mr-1" />
            Wedding Gift
            <feather-icon icon="GiftIcon" size="26" class="ml-1" />
          </div>
        </b-button>

        <b-modal id="gift" class="text-warning" v-model="showGift" hide-header>
          <div
            v-for="(bank, idx) in banks"
            :key="idx"
            class="text-center border-warning rounded p-2 mb-2"
          >
            <h3 class="font-italic">{{ bank.name }}</h3>
            <h3 class="font-weight-bolder">{{ bank.person }}</h3>
            <h3>
              <span
                class="badge border-warning bank-number"
                id="mandiri-number"
                >{{ bank.number }}</span
              >
            </h3>
            <button class="btn btn-warning">
              <span class="font-weight-bolder" @click="copyNumber(bank.number)"
                ><feather-icon icon="CopyIcon" /> Copy</span
              >
            </button>
          </div>
          <template #modal-footer>
            <b-button class="btn btn-warning" @click="showGift = false">
              <feather-icon icon="XIcon" /> Close
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BModal } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["guest"],
  components: {
    BButton,
    BModal,
    ToastificationContent,
  },
  data() {
    return {
      showGift: false,
      banks: [
        {
          name: "MANDIRI",
          person: "ALFREDO SINAGA",
          number: 1240011119089,
        },
        {
          name: "BRI",
          person: "ALFREDO SINAGA",
          number: 752101007609533,
        },
      ],
      wish: { slug: null, sender: "", message: "" },
      wishes: [
        {
          sender: "Jane Doe",
          message:
            "Me too, I wish the best for both of you 🥹. Especially you, Handsome.. 😽",
        },
        {
          sender: "Jon Doe",
          message:
            "I wish I could be there, but I got called by many countries.",
        },
      ],
      loaders: {
        wish: false,
      },
    };
  },
  mounted() {
    this.wish.slug = this.guest.slug;
    if (this.guest.is_group == 0) {
      this.wish.sender = this.guest.name;
    }
    this.getWishes();
  },
  methods: {
    getWishes() {
      this.$http
        .get("https://api-peanut.bangnaga.net/api/wishes/peanut")
        .then((res) => {
          this.wishes = res.data.concat(this.wishes);
        });
    },
    sendWishes() {
      if (this.validateWish()) {
        this.loaders.wish = true;
        this.$http
          .post("https://api-peanut.bangnaga.net/api/wish/peanut", this.wish)
          .then((res) => {
            this.loaders.wish = false;
            this.makeToast(
              `Thank you ${res.data.sender}!`,
              "We'll read your wish leter."
            );
            this.wishes.unshift(res.data);
          })
          .catch((err) => {
            let error = err.response.data;
            this.makeToast("Error!", error.message, "danger", "XCircleIcon");
            this.loaders.wish = false;
          });
      }
    },
    validateWish() {
      if (this.guest.is_group == 1 && !this.wish.sender) {
        this.makeToast(
          "Your name, please.",
          "Insert your name first.",
          "danger",
          "HelpCircleIcon"
        );
        this.$refs.wishSender.focus();
        return false;
      }
      if (this.wish.message == "") {
        this.makeToast(
          "Make a wish.",
          "Insert your wish.",
          "danger",
          "HelpCircleIcon"
        );
        this.$refs.wishMessage.focus();
        return false;
      }
      return true;
    },
    copyNumber(number) {
      navigator.clipboard.writeText(number);
      this.makeToast("Number Copied.");
    },
    makeToast(title, text = "", variant = "warning", icon = "BellIcon") {
      this.$toast(
        {
          component: ToastificationContent,
          props: { variant, title, text, icon },
        },
        { position: "bottom-center" }
      );
    },
  },
};
</script>

<style>
#wish-gift {
  background-image: url("/intro-bg.jpg");
  padding-top: 6rem;
  height: max-content;
}

#wish-bg {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(3px);
}
#wish-gift .wish-flower-top-left,
#wish-gift .wish-flower-bottom-right {
  width: 200px;
  position: absolute;
  z-index: 1;
}
#wish-gift .wish-flower-top-left {
  top: 0;
  left: 0;
  transform: rotate(180deg);
  margin-top: -60px;
  margin-left: -60px;
}

#wish-gift .wish-flower-bottom-right {
  bottom: 0;
  right: 0;
  margin-bottom: -60px;
  margin-right: -60px;
}

#wish-gift .card {
  border-width: 3px !important;
}

.wishes {
  max-height: 300px !important;
  overflow-y: scroll;
}

.wish-wrapper {
  text-align: justify !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.wish-content .card-body {
  padding: 2px 10px;
}
.wish-icon .w-icon {
  width: 100% !important;
  background-color: var(--warning);
  padding: 5px;
  color: white;
  border-radius: 5px;
  text-align: center;
}
.wish-sender {
  font-weight: bolder;
  color: var(--warning);
  margin: 0 0 5px 0;
}

#gift {
  padding: 0;
}
#gift * {
  color: var(--warning) !important;
}
#gift .modal-dialog {
  width: 100vw;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
#gift .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--light) !important;
}

#gift .modal-body .btn-warning * {
  color: var(--light) !important;
}

#gift .modal-footer {
  justify-content: center !important;
}
#gift .modal-footer * {
  color: var(--light) !important;
}
#gift .bank-number {
  /* color: white !important; */
  letter-spacing: 3px;
}
.bottom-center {
  align-items: center;
}
</style>