 
<template>
  <div id="galleries" class="content-section">
    <img src="/flower-2.png" alt="flower" class="w-50 flower-top" />
    <div id="galleries-content" class="py-2">
      <h1 class="font-madina text-warning font-large-3">
        <feather-icon icon="HeartIcon" size="24"></feather-icon>
        Love Gallery
        <feather-icon icon="HeartIcon" size="24"></feather-icon>
      </h1>
      <div class="border-light rounded-lg overflow-hidden">
        <swiper
          ref="swiperTop"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="swiperOptions"
          class="swiper-navigations"
        >
          <swiper-slide v-for="data in swiperData" :key="data.img">
            <b-img :src="data.img" fluid />
          </swiper-slide>
          <div slot="button-next" class="text-secondary swiper-button-next" />
          <div slot="button-prev" class="text-secondary swiper-button-prev" />
        </swiper>

        <swiper
          ref="swiperThumbs"
          class="swiper gallery-thumbs bg-dark bg-darken-2 py-1"
          :options="swiperOptionThumbs"
        >
          <swiper-slide v-for="(data, index) in swiperData" :key="index">
            <b-img :src="data.img" fluid />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";

export default {
  props: ["galleries"],
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        {
          img: "https://api-peanut.bangnaga.net/storage/attachments/KQjK52vkbHmMXDj5kJVjPA6sZMWpCfM9wX8KiyD2.jpg",
        },
        {
          img: "https://pernikahan.bangnaga.net/og-image.jpg",
        },
      ],
      /* eslint-disable global-require */
      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    };
  },
  mounted() {
    this.swiperData = this.galleries;
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
};
</script>

<style>
#galleries {
  background-image: url("/intro-bg.jpg");
}
.swiper-wrapper {
  display: flex;
  align-items: center;
}
.swiper-container {
  background: var(--light);
}
</style>