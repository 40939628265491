<template>
  <div id="intro" class="content-section">
    <img src="/flower-2.png" alt="flower" class="w-50 flower-top fade-in" />
    <div id="intro-content">
      <p class="text-center px-5 fade-in">
        "So they are no longer two, but one flesh. Therefore, what God has
        joined together, no human being must separate."
        <br />
        <br />
        <span class="font-weight-bolder">Matthew 19:6</span>
      </p>
      <img src="/am.png" alt="am" class="img-fluid px-4 my-2 fade-in" />
      <p class="text-center px-5 fade-in">
        By the grace and the blessing of the Lord, we joyfully invite you to our
        wedding.
      </p>
    </div>
    <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom fade-in" />
  </div>
</template>

<script>
export default {};
</script>

<style>
#intro {
  background-image: url("/intro-bg.jpg");
}
#intro-content {
  padding: 4rem 0;
}
</style>