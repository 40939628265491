<template>
  <div id="main-info" class="content-section">
    <img src="/flower-2.png" alt="flower" class="w-50 flower-top fade-in" />
    <div class="scroll-fade-in pt-5">
      <div class="card bg-white overflow-hidden" id="invitation-card">
        <div class="card-body position-relative p-0">
          <img
            src="/og-image.jpg"
            alt="img"
            class="img-fluid"
            style="opacity: 0.4"
          />
          <div
            class="d-flex w-100 h-100 justify-content-center position-absolute p-2"
            style="top: 0"
          >
            <div class="">
              <h1 class="mb-3">
                <feather-icon icon="CalendarIcon" size="72"></feather-icon>
              </h1>
              <h1 style="letter-spacing: 0.5rem">Saturday</h1>
              <h2>June 15, 2024</h2>
              <div class="my-2 d-flex">
                <div
                  class="px-2 border-right border-1"
                  style="border-color: white !important"
                >
                  <h4 class="font-madina font-large-1 m-0">Holy Matrimony</h4>
                  <h1 style="letter-spacing: 0.5rem">09:30 AM</h1>
                </div>
                <div class="px-2">
                  <h4 class="font-madina font-large-1 m-0">
                    Cultural Ceremony
                  </h4>
                  <h1 style="letter-spacing: 0.5rem">12:00 PM</h1>
                </div>
              </div>
              <div>
                <h4>Sopo Godang</h4>
                <h1>HKBP Sentosa</h1>
                <p class="px-2">Jl. Sentosa No.1, Kota Samarinda</p>
                <p>
                  <a
                    href="https://calendar.app.google/punRoaLw3KDgLYyU9"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-light px-3"
                    style="color: var(--dark) !important"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-1"
                    ></feather-icon>
                    Add To Calendar
                  </a>
                </p>
                <p>
                  <a
                    href="https://maps.app.goo.gl/dwrtdDgwL6cknRUq6"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn btn-light px-3"
                    style="color: var(--dark) !important"
                  >
                    <feather-icon icon="MapPinIcon" class="mr-1"></feather-icon>
                    Open map
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="/flower-1.png" alt="flower" class="w-50 flower-bottom fade-in" />
  </div>
</template>

<script>
export default {};
</script>

<style>
#main-info {
  background-image: url("/main-info.jpg");
}

#invitation-card {
  background-image: url("/dark-lovers.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 40px 30px -10px #530;
}
#invitation-card * {
  color: white !important;
}
#invitation-card a svg {
  stroke: var(--dark) !important;
}
</style>